import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export default function Index() {
  const [state, setState] = React.useState({});
  const [thanks, setThanks] = React.useState(false);
  const [errors, setErrors] = React.useState({ email: false });

  const handleChange = (e) => {
    if (e.target.name === "email" && !e.target.value.match(mailformat)) {
      setErrors({ email: true });
    } else if (e.target.name === "email" && e.target.value.match(mailformat)) {
      setErrors({ email: false });
    }

    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!state.email || !state.email.match(mailformat)) {
      return setErrors({ ...errors, email: true });
    }
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        setThanks(true);
      })
      .catch((error) => alert(error));
  };
  return (
    <Layout location="contact">
      <section className="section">
        <div className="container">
          <div className="content">
            {thanks && (
              <div className="flex justify-content-center text-center">
                <div className="bg-gray-400 text-white rounded w-full py-4">
                  Message received, thank you!
                </div>
              </div>
            )}
            
            <form
              name="contact"
              method="post"
              action="/contact/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <div hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={handleChange} />
                </label>
              </div>
              <div className="py-12 text-sm">
                <div className="mt-8 max-w-md">
                  <div className="grid grid-cols-1 gap-6">
                    <label className="block">
                      <span className="text-gray-700">Name</span>
                      <input
                        name={"name"}
                        onChange={handleChange}
                        type="text"
                        className="text-sm mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        placeholder=""
                      />
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Email</span>
                      <input
                        name={"email"}
                        onChange={handleChange}
                        type="email"
                        className={`${
                          errors.email ? "border-red-600" : ""
                        } text-sm mt-1 block w-full border-red rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}
                        placeholder="john@example.com"
                      />
                    </label>
                    <label className="block">
                      <span className="text-gray-700">Message</span>
                      <textarea
                        name={"message"}
                        onChange={handleChange}
                        className="text-sm mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        rows="5"
                      ></textarea>
                    </label>
                  </div>
                </div>
              </div>
              <div className="field">
                <input
                  className="button text-sm is-link bg-blue-400 rounded px-6 py-2 text-white"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
}
